import React, { useEffect, useState } from 'react'
import WelcomeSambay from './WelcomeSambay/WelcomeSambay'
import BannerPromocional from '../../components/BannerPromocional/BannerPromocional'
import SocialProjectsSlider from '../../components/SocialProjectsSlider/SocialProjectsSlider'
import CartProductsSlider from '../../components/CartProductsSlider/CartProductsSlider'
import BanknotesSlider from '../../components/BanknotesSlider/BanknotesSlider'
import Layout from '../../components/Layout/Layout'
import BannerSlider from '../../components/BannerSlider/BannerSlider'
import PaymentSection from './PaymentSection/PaymentSection'
import VehiclesSlider from '../../components/VehiclesSlider/VehiclesSlider'
import PropertiesSlider from '../../components/PropertiesSlider/PropertiesSlider'
import PetsSlider from '../../components/PetsSlider/PetsSlider'
import BooksSlider from '../../components/BooksSlider/BooksSlider'
import AntiquesAndCollectionsSlider from '../../components/AntiquesAndCollectionsSlider/AntiquesAndCollectionsSlider'
import MilitarySlider from '../../components/MilitarySlider/MilitarySlider'
import RailwaysSlider from '../../components/RailwaysSlider/RailwaysSlider'
import MiddleBanner from './MiddleBanner/MiddleBanner'
import ToysAndGamesSlider from '../../components/ToysAndGamesSlider/ToysAndGamesSlider'
import JewelrySlider from '../../components/JewelrySlider/JewelrySlider'
import HighlightsProductsSlider from '../../components/HighlightsProductsSlider/HighlightsProductsSlider'
import ShowCaseDinamic from '../../components/ShowCaseDinamic/ShowCaseDinamic'
import WatchesSlider from '../../components/WatchesSlider/WatchesSlider'
import ArtPiecesSlider from '../../components/ArtPiecesSlider/ArtPiecesSlider'

const HomePage = (): JSX.Element => {
    const [welcome, setWelcome] = useState<boolean>(true)

    useEffect(() => {
        setTimeout(() => {
            setWelcome(false)
        }, 1000)
    }, [])

    return (
        <Layout title="O Marketplace Que Fala Sua Língua! 🦜 🇧🇷">
            {welcome ? (
                <WelcomeSambay />
            ) : (
                <>
                    <BannerSlider />
                    <ShowCaseDinamic />
                    <HighlightsProductsSlider />
                    <BanknotesSlider />
                    <AntiquesAndCollectionsSlider />
                    <ArtPiecesSlider />
                    <ToysAndGamesSlider />
                    <MiddleBanner />
                    <JewelrySlider />
                    <WatchesSlider />
                    <BooksSlider />
                    <PetsSlider />
                    <MilitarySlider />
                    <PaymentSection />
                    <RailwaysSlider />
                    <SocialProjectsSlider />
                    <VehiclesSlider />
                    <PropertiesSlider />
                    <CartProductsSlider />
                    <BannerPromocional />
                </>
            )}
        </Layout>
    )
}

export default HomePage
